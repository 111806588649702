.cursor {
  $root: &;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 250;

  direction: ltr;
  contain: layout style size;
  pointer-events: none;

  transition: opacity 0.3s, color 0.4s;

  &:before {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    display: block;
    // width: 116px;
    // height: 116px;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    transition: transform 0.25s ease-in-out, opacity 0.1s;
    background-color: $color-green;
    background-image: url('../public/svgs/cursor.svg');
    background-repeat: no-repeat;
    background-size: 0;
    background-position: center;
  }
}
