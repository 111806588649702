@import "/styles/variables.scss"; @import "/styles/mixins.scss"; @import "/styles/cursor.scss"; @import "/styles/nprogress.scss";
.mainContainer {
  position: relative;
  overflow: hidden;
  background-color: transparent;
}

.section {
  composes: landingSection from global;
}

.animatedShape {
  position: absolute;
  bottom: -173%;
  right: -47%;
  transform: rotate(0deg);
}

.contentWrapper {
  @include absoluteCenter;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  top: 57%;
  flex-direction: column;
  height: 50%;
  width: 100%;

  @include respond(l-desktop) {
    justify-content: center;
    top: 55%;
  }
  @include respond(desktop) {
    top: 54%;
  }
  @include respond(tablet) {
    top: 50%;
  }
}

.tag {
  transform: translateY(-200%);

  @include respond(l-desktop) {
    transform: translateY(-155%);
  }
  @include respond(desktop) {
    transform: translateY(-150%);
  }
  @include respond(mobile) {
    transform: translateY(-100%);
  }
}

.textWrapper {
  position: relative;
}

.titleLine {
  // height: 120px;
  height: fit-content;
  line-height: 12em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media only screen and (max-width: 93.75em) {
    line-height: 11em;
  }
  @include respond(xl-desktop) {
    line-height: 9.5em;
  }
  @include respond(l-desktop) {
    line-height: 7.5em;
  }
  @include respond(desktop) {
    line-height: 6.5em;
  }
  @include respond(tablet) {
    line-height: 5em;
  }
  @include respond(large-mobile) {
    line-height: 6.5em;
  }
  @include respond(mobile) {
    line-height: 4.7em;
  }
}

.titlePrimary {
  @include tyH1;
  text-transform: uppercase;

  @media only screen and (max-width: 100em) {
    font-size: 14em;
  }
  @include respond(xl-desktop) {
    font-size: 12rem;
  }
  @include respond(l-desktop) {
    font-size: 9rem;
  }
  @include respond(desktop) {
    font-size: 8rem;
  }
  @include respond(tablet) {
    font-size: 6rem;
  }
  @include respond(large-mobile) {
    font-size: 8rem;
  }
  @include respond(mobile) {
    font-size: 6rem;
  }
}

.cta {
  margin-top: 4em;
}

.vertical {
  @include tyLabelsMed;
  position: absolute;
  top: 50%;
  writing-mode: vertical-lr;
}

.leftVertical {
  composes: vertical;
  left: 80px;
  transform: translate(0, -50%) rotate(180deg);
}
.rightVertical {
  composes: vertical;
  right: 80px;
  transform: translate(0, -50%);
}
