// MEDIA QUERY MANAGER
/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop
$breakpoint argument choices:
- phone
- tab-port
- tab-land
- big-desktop
ORDER: Base + typography > general layout + grid > page layout > components
1em = 16px
*/

@mixin respond($breakpoints...) {
  @each $breakpoint in $breakpoints {
    @if $breakpoint == mobile {
      @media only screen and (max-width: 30em) {
        @content;
      } //480px
    }
    @if $breakpoint == large-mobile {
      @media only screen and (max-width: 37.5em) {
        @content;
      } //600px
    }
    @if $breakpoint == tablet {
      @media only screen and (max-width: 48em) {
        @content;
      } //768px
    }
    // @if $breakpoint == tab-port {
    //   @media only screen and (max-width: 56.25em) {
    //     @content;
    //   } //900px
    // }
    @if $breakpoint == desktop {
      @media only screen and (max-width: 64em) {
        @content;
      } //1024px
    }
    @if $breakpoint == l-desktop {
      @media only screen and (max-width: 75em) {
        @content;
      } //1200px
    }
    @if $breakpoint == xl-desktop {
      @media only screen and (max-width: 87.5em) {
        @content;
      } //1400px
    }
    @if $breakpoint == xxl-desktop {
      @media only screen and (min-width: 120em) {
        @content;
      } // 1920px
    }
  }
}

/* =========== POSITIONING =========== */
@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin absoluteCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* =========== TYPOGRAPHY =========== */
@mixin tyH1 {
  font-family: $font-milo-for-manor-regular;
  font-style: normal;
  font-weight: 350;
  font-size: 16rem;
  letter-spacing: -0.05em;

  @include respond(xl-desktop) {
    font-size: 14rem;
  }
  @include respond(l-desktop) {
    font-size: 13rem;
  }
  @include respond(desktop) {
    font-size: 9rem;
  }
  @include respond(tablet) {
    font-size: 9rem;
  }
  @include respond(mobile) {
    font-size: 6.6rem;
  }
}

@mixin tyH2 {
  font-family: $font-milo-for-manor-regular;
  font-style: normal;
  font-weight: 350;
  font-size: 11.6rem;
  letter-spacing: -0.05em;

  @include respond(xl-desktop) {
    font-size: 10rem;
  }
  @include respond(l-desktop) {
    font-size: 8rem;
  }
  @include respond(desktop) {
    font-size: 6rem;
  }
  @include respond(tablet) {
    font-size: 9rem;
  }
  @include respond(large-mobile) {
    font-size: 7rem;
  }
  @include respond(mobile) {
    font-size: 5.4rem;
  }
}

@mixin tyH3 {
  font-family: $font-milo-for-manor-regular;
  font-style: normal;
  font-weight: 350;
  font-size: 5rem;
  letter-spacing: -0.02em;

  @include respond(tablet) {
    font-size: 4rem;
  }
  @include respond(large-mobile) {
    font-size: 3rem;
  }
}

@mixin tyH4 {
  font-family: $font-milo-for-manor-light;
  font-style: normal;
  font-weight: 350;
  font-size: 4rem;
  line-height: 104.4%;
  letter-spacing: -0.05em;
}

@mixin tyBigParagraph {
  font-family: $font-milo-for-manor-text;
  font-style: normal;
  font-weight: 450;
  font-size: 2.8rem;
  letter-spacing: -0.005em;

  @include respond(l-desktop) {
    font-size: 2.6rem;
  }

  @include respond(desktop) {
    font-size: 2.4rem;
  }

  @include respond(tablet) {
    font-size: 2.2rem;
  }

  @include respond(mobile) {
    font-size: 1.6rem;
  }
}

@mixin tyLabelsRegular {
  font-family: $font-milo-for-manor-regular;
  font-weight: 400;
  font-size: 2.8rem;
  line-height: 34px;
  letter-spacing: -0.005em;

  @include respond(l-desktop) {
    font-size: 2.6rem;
  }

  @include respond(desktop) {
    font-size: 2.4rem;
  }

  @include respond(tablet) {
    font-size: 2.2rem;
  }

  @include respond(mobile) {
    font-size: 1.6rem;
  }
}

@mixin tyLabelsMed {
  font-family: $font-milo-for-manor-medium;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  letter-spacing: 0.015em;

  @include respond(l-desktop) {
    font-size: 1.4rem;
  }

  @include respond(desktop) {
    font-size: 1.4rem;
  }

  @include respond(mobile) {
    font-size: 1.4rem;
  }
}

@mixin tyLabelsBold {
  font-family: $font-milo-for-manor-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 1.6rem;
  letter-spacing: 0.015em;

  @include respond(large-mobile) {
    letter-spacing: 0.005em;
  }
}

@mixin tySmallParagraph {
  font-family: $font-milo-for-manor-text;
  font-style: normal;
  font-weight: 450;
  font-size: 1.6rem;
  line-height: 2.1rem;
  letter-spacing: 0.015em;

  @include respond(phone) {
    line-height: normal;
    letter-spacing: 0.05rem;
  }
}

@mixin tyCtas {
  font-family: $font-milo-for-manor-medium;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  letter-spacing: 0.1em;

  @include respond(l-desktop) {
    font-size: 1.4rem;
  }

  @include respond(desktop) {
    font-size: 1.4rem;
  }

  @include respond(mobile) {
    font-size: 1.4rem;
  }
}

@mixin blurredModal {
  text-align: center;
  @include tyBigParagraph;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 45vw;
  height: fit-content;
  background: rgba(255, 255, 255, 0.14);
  backdrop-filter: blur(187px);
  border-radius: 50px;
  padding: 5%;
  max-width: 870px;

  @include respond(desktop) {
    width: 80%;
  }

  @include respond(large-mobile) {
    background: none;
    width: 100%;
    border-radius: 0px;
  }
}

@mixin tyRegularParagraph {
  font-family: $font-milo-for-manor-regular;
  font-style: normal;
  font-weight: 450;
  font-size: 1.6rem;
  line-height: 2.1rem;
  letter-spacing: 0.015em;

  @include respond(phone) {
    line-height: normal;
    letter-spacing: 0.05rem;
  }
}
