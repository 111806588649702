/*********** COLORS ***********/
$color-primary: #000000;
$color-secondary: #ffffff;
$color-tertiary: #0070f3;
$color-black: #27272a;
$color-white: #ffffff;
$color-yellow: #e3fc5d;
$color-yellow-dark: #e3fc5d;
$color-orange: #ffc93e;
$color-orange-dark: #ff8e01;
$color-green: #d2e8df;
$color-link: #0070f3;
$color-grey: #dbd5d1;
$color-grey-transparent: #dbd5d120;
$color-lilac: #ccc9f4;
$color-red: #f94949;

/*********** FONTS ***********/

@font-face {
  font-family: 'MiloforManorRegular';
  src: url('/fonts/MiloforManor.woff2') format('woff2');
  font-style: normal;
}
$font-milo-for-manor-regular: 'MiloforManorRegular';

@font-face {
  font-family: 'MiloforManorLight';
  src: url('/fonts/MiloforManor-Light.woff2') format('woff2');
  font-weight: 350;
}
$font-milo-for-manor-light: 'MiloforManorLight';

@font-face {
  font-family: 'MiloforManorText';
  src: url('/fonts/MiloforManor-Text.woff2') format('woff2');
  font-weight: 450;
}
$font-milo-for-manor-text: 'MiloforManorText';

@font-face {
  font-family: 'MiloforManorMedium';
  src: url('/fonts/MiloforManor-Medi.woff2') format('woff2');
  font-weight: 500;
}
$font-milo-for-manor-medium: 'MiloforManorMedium';

@font-face {
  font-family: 'MiloforManorBold';
  src: url('/fonts/MiloforManor-Bold.woff2') format('woff2');
  font-weight: 700;
}
$font-milo-for-manor-bold: 'MiloforManorBold';

/*********** SIZES ***********/
$navbar-height: 100px;
$navbar-height-mobile: 66px;
$footer-height: 250px;
$footer-height-mobile: 443px;

/*********** ANIMATION ***********/
$transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);

/*********** BREAKPOINTS ***********/
// TODO: Quizá los quitemos (depende de diseños)
$screen-xxs: 320px;
$screen-xxs-plus: 400px;
/* Extra Small Devices, Phones */
$screen-xs: 480px;
$screen-xs-plus: 560px;
/* Small Devices, Tablets */
$screen-sm-minus: 680px;
$screen-sm: 768px;
/* Medium Devices, Desktops */
$screen-md: 992px;
/* Large Devices, Wide Screens */
$screen-lg: 1200px;
/* Extra Large Devices, Wide Screens */
$screen-xl: 1600px;
/* Extra media queries by devices
   Medium max width tablet */
$screen-md-max-tablet: 1024px;

/* Z INDEX */
$z-index-back-shape: 1;
$z-index-shape: 2;
$z-index-front-shape: 3;
$z-index-lateral-texts: 98;
$z-index-modal: 99;
$z-index-footer: 100;
$z-index-navbar: 100;
$z-index-cursor: 101;
$z-index-overlay: 102;
